<template>
  <feature-teaser-dialog :title="$t('agenda.agendaTeaserTitle')">
    <p>{{ $t('agenda.agendaTeaserWarning') }}</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
