<template>
  <div>
    <div>
      <h4 class="mt-1 ml-3 mb-2">{{ groupLocalization('EC') }}</h4>
      <treeview-root
        class="px-5"
        v-model="ecCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.ecTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EP-PL') }}</h4>
      <treeview-root
        class="px-5"
        v-model="epPlCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.epPlTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EP-COM') }}</h4>
      <div class="mx-3 mb-n5">
        <v-autocomplete
          v-model="selectedCommittees"
          :items="committees"
          :label="$t('eu.selectCommittees')"
          :item-text="userPreferences.locale"
          item-value="code"
          multiple
          dense
          @input="handleCheck"
          outlined
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon :color="selectedCommittees.length > 0 ? 'teal' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{$t('generic.selectAll')}} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ index }">
            <div v-if="index === 0" class="grey--text text-caption">
              {{ selectedCommittees.length }} {{$t('generic.selected')}}...
            </div>
          </template>
        </v-autocomplete>
      </div>
      <v-tooltip :disabled="!disableComTypes" close-delay="200" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <treeview-root
              class="px-5"
              :class="{ 'disable-types': disableComTypes }"
              v-model="epComCheckedGroupPaths"
              open-all
              selected-color="teal lighten-1"
              :items="items.epComTypes"
              @input="handleCheck"
              item-key="groupPath"
              item-text="localization"
              :virtual-root-node="true"
            />
          </div>
        </template>
        <span>{{ $t('eu.selectCommissionDropdown') }} </span>
      </v-tooltip>
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EP-COM-OVERIG') }}</h4>
      <treeview-root
        class="px-5"
        v-model="overigeCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.epComOverigTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EU-RAAD') }}</h4>
      <treeview-root
        class="px-5"
        v-model="euRaadCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.euRaadTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EU-SH') }}</h4>
      <treeview-root
        class="px-5"
        v-model="stakeholdersCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.stakeholderTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div>
      <h4 class="mt-5 ml-3 mb-2">{{ groupLocalization('EU-MD') }}</h4>
      <treeview-root
        class="px-5"
        v-model="mediaCheckedGroupPaths"
        open-all
        selected-color="teal lighten-1"
        :items="items.mediaTypes"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div v-if="items.mediaTypes || items.stakeholderTypes" class="text-caption mt-2 ml-1 py-2 pt-0 font-italic">
      {{ $t('generic.updatesExcluded') }}
    </div>
  </div>
</template>

<script>
import TreeviewRoot from '@/components/Treeview/Treeview.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { convertGroupToFilterStructure, formatLocalization } from '@/helpers/groupingHelper';

export default {
  components: {
    TreeviewRoot,
  },

  data: () => ({
    ecCheckedGroupPaths: [],
    epPlCheckedGroupPaths: [],
    epComCheckedGroupPaths: [],
    overigeCheckedGroupPaths: [],
    stakeholdersCheckedGroupPaths: [],
    mediaCheckedGroupPaths: [],
    euRaadCheckedGroupPaths: [],
    selectedCommittees: [],
    filterType: 'euGrouping',
    committeesFilterType: 'euEpCommittees',
    showTooltip: false,
  }),

  computed: {
    ...mapGetters({
      allSearchParameters: 'allSearchParameters',
      searchParameterGroupPaths: 'searchParameterGroupPaths',
      searchParameterEuEpCommittees: 'searchParameterEuEpCommittees',
      userFilters: 'userFilters',
      userPreferences: 'userPreferences',
    }),

    items() {
      const grouping = this.userFilters[this.filterType];
      // Update grouping details for searchParameters
      const { name, version } = grouping;
      this.setGroupingDetails({ name, version });

      // Here we manually break open the grouping so we can render it in different sections, with headers.
      const items = [
        { groupCode: 'EC', varName: 'ecTypes' },
        { groupCode: 'EP-PL', varName: 'epPlTypes' },
        { groupCode: 'EP-COM', varName: 'epComTypes' },
        { groupCode: 'EP-COM-OVERIG', varName: 'epComOverigTypes' },
        { groupCode: 'EU-RAAD', varName: 'euRaadTypes' },
        { groupCode: 'EU-SH', varName: 'stakeholderTypes' },
        { groupCode: 'EU-MD', varName: 'mediaTypes' },
      ]
        .filter(({ groupCode }) => !!grouping.groups[groupCode])
        .reduce((filters, { groupCode, varName }) => {
          filters[varName] = [
            convertGroupToFilterStructure(groupCode, grouping.groups[groupCode]),
          ];
          return filters;
        }, {});

      // Add * to some filter localizations (not headers):
      const ecPressCorner = items.ecTypes[0]?.children
        ?.find((child) => child.groupPath === 'EC/SB-7');
      if (ecPressCorner) ecPressCorner.localization += '*';
      const ecouPress = items.euRaadTypes[0]?.children
        ?.find((child) => child.groupPath === 'EU-RAAD/SB-4');
      if (ecouPress) ecouPress.localization += '*';

      return items;
    },

    disableComTypes() {
      return this.selectedCommittees.length === 0;
    },

    committees() {
      const committees = this.userFilters[this.committeesFilterType];
      return committees;
    },

    likesAllCommittees() {
      return this.selectedCommittees.length === this.committees.length;
    },

    likesSomeCommittees() {
      return this.selectedCommittees.length > 0 && !this.likesAllCommittees;
    },

    icon() {
      if (this.likesAllCommittees) return 'mdi-close-box';
      if (this.likesSomeCommittees) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  watch: {
    searchParameterEuEpCommittees(committees) {
      this.selectedCommittees = committees;
    },
    searchParameterGroupPaths(newGroupPaths) {
      this.ecCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EC'))
        : [];
      this.epPlCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EP-PL'))
        : [];
      this.epComCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.match(/^EP-COM($|\/)/))
        : [];
      this.overigeCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EP-COM-OVERIG'))
        : [];
      this.euRaadCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EU-RAAD'))
        : [];
      this.stakeholdersCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EU-SH'))
        : [];
      this.mediaCheckedGroupPaths = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('EU-MD'))
        : [];
    },
  },

  methods: {
    ...mapState(['searchParameters']),
    // TODO: Check naming for filters
    ...mapActions([
      'euGetAllTimelineItems',
      'setGroupPaths',
      'setEuEpCommittees',
      'setGroupingDetails',
    ]),

    setQueryAndFilters(groupPaths) {
      // Set the selected filters as querystring of the dashboard
      const filters = {
        groupPaths: groupPaths.join(','),
        committees: this.selectedCommittees.join(',') || [],
      };

      this.$router
        .push({
          name: 'euDashboard',
          query: { ...this.$route.query, ...filters },
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('route', err);
        });

      this.euGetAllTimelineItems();
    },

    handleCheck() {
      const groupPaths = [
        ...this.ecCheckedGroupPaths,
        ...this.epPlCheckedGroupPaths,
        ...this.overigeCheckedGroupPaths,
        ...this.euRaadCheckedGroupPaths,
        ...this.stakeholdersCheckedGroupPaths,
        ...this.mediaCheckedGroupPaths,
        ...(this.disableComTypes ? [] : this.epComCheckedGroupPaths),
      ];
      const committees = this.selectedCommittees;
      this.setGroupPaths(groupPaths);
      this.setEuEpCommittees(committees);
      this.setQueryAndFilters(groupPaths);
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllCommittees) {
          this.selectedCommittees = [];
        } else {
          const list = [];
          this.committees.slice().forEach((item) => {
            list.push(item.code);
          });
          this.selectedCommittees = list;
        }
        this.handleCheck();
      });
    },

    groupLocalization(groupPath, locale = 'nl') {
      const userLocale = this.$i18n.locale || locale;
      const grouping = this.userFilters[this.filterType];
      if (grouping && grouping.groups[groupPath]) {
        const defaultLocalization = grouping.groups[groupPath].localization.nl;
        const groupLocalization = grouping.groups[groupPath].localization[userLocale];
        const localization = groupLocalization?.plural || groupLocalization || defaultLocalization;
        return formatLocalization(localization, groupPath);
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-types {
  opacity: 0.5;
  pointer-events: none;
}
</style>
