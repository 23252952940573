<!--
  Component which lists the timeline items
-->

<template>
  <div>
      <v-subheader
        v-if="timelineItems.length > 0"
        class="text-subtitle-1 text--primary d-inline-block"
      >
        {{ $t('timeline.searchPeriod') }}: <span class="font-weight-medium">{{ this.expandedDateRange ? maxDateRangeTimelineItemsTotal : countTotal }} </span> {{ $t('timeline.outOf') }}
          <span class="font-weight-medium">{{ maxDateRangeTimelineItemsTotal }}</span> {{ $t('timeline.results') }}
      </v-subheader>

    <v-divider
      v-if="timelineItems.length > 0"
    />

    <div class="timeline-container">
      <div
        v-for="(group, date) in groupedTimelineItems"
        :key="date"
        class="date-group"
      >
        <v-subheader
          class="date-header text-body1 text-uppercase text--primary font-weight-medium sticky-header"
          :key="`sh-${date}`"
        >
          {{ dateAsString(date) }}
        </v-subheader>

        <div
          v-for="item in group"
          :key="item.id"
        >
          <list-timeline-item
            v-bind="item"
            :dashboard="dashboard"
          />
        </div>
      </div>
    </div>

    <v-btn
      v-if="hasMore && !busyLoadingMore"
      @click="loadMore()"
      block
    >
    {{ $t('generic.showMore') }}
    </v-btn>

    <v-btn
      v-if="!hasMore && this.$route.query.startDate
        && (new Date(this.$route.query.startDate).getFullYear() === 1970)
        && prevTotal < timelineItems.length"
      @click="loadMore()"
      block
    >
    {{ $t('generic.showMore') }}
    </v-btn>

    <v-btn
      v-if="!hasMore
        && new Date(this.$route.query.startDate).getFullYear() !== 1970
        && ((countTotal > 0 && countTotal === timelineItems.length)
          || (countTotal === 0 && lookBack))"
      @click="expandDateRange()"
      block
    >
      {{ $t('generic.lookFurtherBack')}}
    </v-btn>

    <v-btn
      v-if="busyLoadingMore && !loaded"
      block
    >
      <loading-spinner />
    </v-btn>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/Spinner.vue';
import ListTimelineItem from './ListTimelineItem.vue';

export default {
  name: 'ListTimeline',

  $el: '#results',

  components: {
    // ContentPanel,
    ListTimelineItem,
    LoadingSpinner,
  },

  props: {
    dashboard: {
      type: String,
      required: true,
    },
  },

  computed: {
    maxDateRangeTimelineItemsTotal() {
      return this.$store.getters[`${this.dashboard}MaxDateRangeTimelineItemsTotal`];
    },

    nextItemUrl() { return this.$store.getters[`${this.dashboard}NextItemUrl`]; },

    timelineItems() { return this.$store.getters[`${this.dashboard}TimelineItems`]; },

    countTotal() { return this.$store.getters[`${this.dashboard}TimelineItemsCountTotal`]; },

    expandedDateRange() { return this.$route.query.startDate === new Date(0).toISOString(); },

    hasMore() {
      return this.$store.getters[this.expandedDateRange
        ? `${this.dashboard}MaxDateRangeTimelineItemsHasMore`
        : `${this.dashboard}TimelineItemsHasMore`
      ];
    },

    loaded() {
      if (this.expandedDateRange) {
        return (this.maxDateRangeTimelineItemsTotal === this.timelineItems.length);
      }
      return this.countTotal === this.timelineItems.length;
    },

    lookBack() { return this.$store.getters.lookBack; },

    groupedTimelineItems() {
      const groups = {};
      this.timelineItems.forEach((item) => {
        groups[item.date] ??= [];
        groups[item.date].push(item);
      });
      return groups;
    },
  },

  data() {
    return {
      busyLoadingMore: false,
      benched: 0,
      mainContentContainer: null,
      prevTotal: 0,
    };
  },

  created() {
    window.addEventListener('scroll', this.scrollTimeline);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollTimeline);
  },

  methods: {
    async scrollTimeline() {
      // https://stackoverflow.com/questions/45121034/infinite-scrolling-with-ajax-does-not-work-with-chrome
      const reachedBottom = document.documentElement.scrollTop
          + window.innerHeight >= (document.documentElement.offsetHeight - 100);
      if (reachedBottom && this.busyLoadingMore === false) {
        await this.loadMore();
      }
    },

    dateAsString(date) {
      return this.$moment(date).format('dddd D MMMM YYYY');
    },

    async loadMore() {
      this.busyLoadingMore = true;
      this.prevTotal = await this.timelineItems.length;
      // We have to check whether there are already timeline items.
      // If not then fire the initial call again with a wider date range.
      if (this.timelineItems.length > 0) {
        await this.$store.dispatch(`${this.dashboard}GetAllTimelineItemsNext`);
      } else {
        await this.$store.dispatch(`${this.dashboard}GetAllTimelineItems`);
      }
      this.busyLoadingMore = false;
    },

    async expandDateRange() {
      // Expand date range to the max
      const dateRangeISO = {
        startDate: new Date(0).toISOString(),
        endDate: new Date().toISOString(),
      };

      this.$store.dispatch('setLookBack', false);
      this.$store.dispatch('setDateRange', dateRangeISO);

      await this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, ...dateRangeISO },
      }).catch(() => {});
      await this.loadMore();
    },
  },
};
</script>

<style scoped lang="scss">
  .timeline-container {
    position: relative;
    overflow: visible;
  }

  .date-group {
    position: relative;
  }

  .date-header {
    letter-spacing: 0.1rem;
    margin: 0;

    &.sticky-header {
      position: -webkit-sticky;
      position: sticky;
      top: 64px;
      z-index: 4;
      background-color: var(--v-background-base);
      backdrop-filter: blur(10px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
</style>
