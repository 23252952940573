<template>
  <div id="dashboard">
    <saved-searches-form
      :dashboard="dashboard"
    />

    <base-layout>
      <template v-slot:drawer-left>
        <nl-sidebar v-if="dashboard === 'nl' && userFiltersAreSet" />
        <nl-lr-sidebar v-if="dashboard === 'nlLr' && userFiltersAreSet" />
        <eu-sidebar v-if="dashboard === 'eu' && userFiltersAreSet" />
      </template>

      <template v-slot:identity>
        <a
          :href="`${kebabDashboard}/dashboard`"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            :src="logoSrc"
            :alt="$t('generic.polpoPolicyMonitoring')"
            height="44"
          >
        </a>
      </template>

      <template v-slot:searchbar>
        <search-bar
          :on-search-action="`${dashboard}GetAllTimelineItems`"
          set-query-action="setQuery"
          search-query="searchParameterQuery"
          :query-length="1000"
          :placeholder="$t('generic.search')"
        />
      </template>

      <template v-slot:main-content>
        <v-container
          fluid
        >
          <v-row
            v-if="timelineItemsCountTotal > 0 && statisticsTrend"
          >
            <v-col cols="12">
              <v-expansion-panels
                :focusable="false"
                v-if="trendAnalyticsExpanded"
                v-model="singlepanel"
                multiple
              >
                <trend-analytics-chart
                  :statisticsTrendType="`${dashboard}StatisticsTrend`"
                  :statisticsTimelineType="`${dashboard}GetAllTimelineItems`"
                />
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="timelineItemsCountTotal >= 0"
              cols="12"
              lg="6"
              xl="6"
            >
              <list-timeline
                :dashboard="dashboard"
              />
            </v-col>
            <v-col
              v-if="timelineItemsCountTotal > 0 && statisticsTrend"
              cols="12"
              lg="6"
              xl="6"
              class="d-none d-md-block"
            >
              <v-expansion-panels
                :focusable="false"
                v-model="panel"
                multiple
              >
                <trend-analytics-chart
                  v-if="!trendAnalyticsExpanded"
                  :statisticsTrendType="`${dashboard}StatisticsTrend`"
                  :statisticsTimelineType="`${dashboard}GetAllTimelineItems`"
                />
                <list-stakeholders
                  :title="$t('activities.activity')"
                  :analyticsConfigKey="`${dashboard}AnalyticsTypes`"
                  :analyticsGetter="`${dashboard}StatisticsStakeholders`"
                  :analyticsConfigDefaultKey="`${dashboard}DefaultAnalyticsCode`"
                  :analyticsLocalStorageKey="analyticsLocalStorageKey"
                  :analyticsFunction="`${dashboard}GetStatistics`"
                />
                <list-trending-topics
                  v-if="dashboard !== 'eu'"
                  :trendingTopicsType="`${dashboard}StatisticsTrendingTopics`"
                />
              </v-expansion-panels>
              <v-btn v-if="hasDebugAccess" color="primary" @click="showStatsDialog = true" class="mt-4">{{ $t('timeline.viewStatistics')}}</v-btn>
              <stats-dialog
                v-model="showStatsDialog"
                :dashboard=dashboard
              />

              <list-agenda
                v-if="dashboard === 'nl'"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:drawer-right>
        <saved-searches
          class="mt-n4"
          :dashboard="dashboard"
        />
      </template>

    </base-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { selectLogo } from '@/helpers/localeHelper';

// Import the logo images
import logoPolpoLightEnglish from '@/assets/logo-transparent-english-small.png';
import logoPolpoDark from '@/assets/polpo-logo-dark.png';
import logoPolpoLight from '@/assets/polpo-logo-light.png';

// @ is an alias to /src
import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import SearchBar from '@/components/SearchBar.vue';
import SavedSearches from '@/components/SavedSearches/SavedSearches.vue';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import AgendaTeaserDialog from '@/modules/agendaDashboard/components/AgendaTeaserDialog.vue';
import { showDialog } from '@/helpers/dialogHelper';

import SavedSearchesForm from '../components/SavedSearchesForm.vue';

import NlSidebar from '../nlDashboard/components/NlSidebar.vue';
import NlLrSidebar from '../nlLrDashboard/components/NlLrSidebar.vue';
import EuSidebar from '../euDashboard/components/EuSidebar.vue';
import ListStakeholders from '../components/ListStakeholders.vue';
import ListTimeline from '../components/ListTimeline.vue';
import ListTrendingTopics from '../components/ListTrendingTopics.vue';
import ListAgenda from '../components/ListAgenda.vue';
import OldLinkWarning from '../components/OldLinkWarning.vue';
import TrendAnalyticsChart from '../components/TrendAnalyticsChart.vue';
import StatsDialog from '../components/StatsDialog.vue';

export default {

  components: {
    SavedSearchesForm,
    BaseLayout,
    TrendAnalyticsChart,
    NlSidebar,
    NlLrSidebar,
    EuSidebar,
    SearchBar,
    ListTimeline,
    ListAgenda,
    ListStakeholders,
    ListTrendingTopics,
    SavedSearches,
    StatsDialog,
  },

  props: ['dashboard'],

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      agendas: 'agendaAll',
      searchParameters: 'allSearchParameters',
      userPreferences: 'userPreferences',
      trendAnalyticsExpanded: 'trendAnalyticsExpanded',
      isIE11: 'isIE11',
    }),

    // These have to be a functions instead of being added via mapGetters
    // because of the availabilty of the `this.dashboard` param.
    timelineItems() {
      return this.$store.getters[`${this.dashboard}TimelineItems`];
    },
    timelineItemsCountTotal() {
      return this.$store.getters[`${this.dashboard}TimelineItemsCountTotal`];
    },
    statisticsTrend() {
      return this.$store.getters[`${this.dashboard}StatisticsTrend`];
    },
    hasDebugAccess() {
      return this.authHasModuleAccess('MC99');
    },

    capitalizedDashboard() {
      return this.dashboard[0].toUpperCase() + this.dashboard.slice(1);
    },

    analyticsLocalStorageKey() {
      if (this.dashboard === 'nl') return 'stakeholderCode';
      if (this.dashboard === 'eu') return 'analyticsCode';
      if (this.dashboard === 'nlLr') return 'nlLrAnalyticsCode';
      throw new Error(`Missing analyticsLocalStorageKey for dashboard ${this.dashboard}`);
    },

    kebabDashboard() {
      return this.dashboard
        .replace(/([A-Z])/g, '-$1') // Prepend capitals with a -
        .toLowerCase(); // Lowercase the capitals
    },

    logoSrc() {
      const logoType = selectLogo(this.userPreferences.locale, this.$vuetify.theme.dark);
      switch (logoType) {
        case 'light-english':
          return logoPolpoLightEnglish;
        case 'dark':
          return logoPolpoDark;
        case 'light':
        default:
          return logoPolpoLight;
      }
    },
  },

  data: () => ({
    userFiltersAreSet: false,
    toggleTrendAnalyticsExpanded: false,
    singlepanel: [0],
    panel: [0, 1, 2],
    showStatsDialog: false,
  }),

  methods: {
    async initialize() {
      this.$gtag.pageview({
        page_path: `${this.dashboard ? `/${this.dashboard}` : ''}/dashboard`,
      });

      // Clear all searchParams to prevent dashboard messing with each others params
      await this.$store.dispatch('clearAllSearchParameters');

      // This is here because there is a glitch
      // when returning from another route
      await this.$store.dispatch(`${this.dashboard}ClearTimelineItems`);
      await this.$store.dispatch(`${this.dashboard}ClearStatistics`);
      await this.$store.dispatch(`${this.dashboard}ResetCurrentSearch`);
      await this.$store.dispatch(`${this.dashboard}SetRecentSearches`, []);

      await Promise.all([
        // Get user preferences // Disabled this due to bug in resizable left hand navigation drawer and uiDarkMode
        // Too many calls to the backend
        // this.$store.dispatch('getPreferences'),

        // We want the filters for the user and a signal when they are available
        this.$store.dispatch('getDocumentFilterTypesForUser'),

        // For NL dashboard only we need the agenda filters, as we show agenda on the dashboard:
        ...(this.dashboard === 'nl' ? [this.$store.dispatch('getAgendaFilterTypesForUser')] : []),
      ]);

      // Get the savedsearches for the dashboard
      // Need to run AFTER we have fetched the userFilters
      await this.$store.dispatch(`${this.dashboard}GetAllSavedSearches`);

      this.$vuetify.theme.dark = this.$store.getters.userPreferences.uiDarkMode;
      this.userFiltersAreSet = true;

      // If URL parameters are available, we need to set stuff accordingly
      await this.$store.dispatch('clearMessages');
      await this.parseQueryParams(this.$route.query);
    },
    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);
    },
    showTeaserAgendaModule() {
      showDialog(AgendaTeaserDialog);
    },

    async parseQueryParams(queryParams) {
      const {
        query,
        context,
        groupPaths,
        documentTypes,
        committees,
        docTypesEc, // old
        docTypesEpPl, // old
        docTypesEpCom, // old
      } = queryParams;

      const dateRangeISO = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      };

      // Check if there are any query parameters set
      if (
        query
        || context
        || groupPaths
        || documentTypes
        || dateRangeISO.startDate
        || dateRangeISO.endDate
        || committees
        || docTypesEc
        || docTypesEpPl
        || docTypesEpCom
      ) {
        if (documentTypes || docTypesEc || docTypesEpPl || docTypesEpCom) {
          showDialog(OldLinkWarning);
        }

        if (query) {
          this.$store.dispatch('setQuery', query);
        }

        // For nl dashboard only:
        const contextsPresent = context && context.split(',').length > 0;
        if (contextsPresent) {
          this.$store.dispatch('setContext', context.split(','));
        }

        this.$store.dispatch('setGroupPaths', (groupPaths && groupPaths.split(',').length > 0) ? groupPaths.split(',') : []);

        // For EU only
        if (committees) {
          this.$store.dispatch('setEuEpCommittees', committees.split(','));
        }

        if (this.$route.query.startDate && this.$route.query.endDate) {
          this.$store.dispatch('setDateRange', dateRangeISO);
        }

        const contextRequired = this.dashboard === 'nl';
        if (query && (!contextRequired || contextsPresent)) {
          this.$store.dispatch(`${this.dashboard}GetAllTimelineItems`);
        }
      }
    },
  },

  watch: {
    trendAnalyticsExpanded(bool) {
      // Lets open the panels after the user altered the settings
      if (!bool) {
        this.panel = [0, 1, 2];
      } else {
        this.singlepanel = [0];
      }
    },
    dashboard() {
      this.initialize();
    },
  },

  mounted() {
    this.initialize();

    this.$store.dispatch('setIsIE11');
    if (this.isIE11) {
      this.panel = [1, 2];
    }
  },

};
</script>

<style lang="scss"> // Carefull, block not scoped
  ul.plain-li {
    & {
      list-style: none;
    }
    & li::before {
      content: "\2022";
      color: teal;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
</style>
