<!--
  Component which displays a list of all trending topics with more/less button
-->

<template>
  <!-- Use v-show instead of v-if, so the open/closed state is saved when it is not shown  -->
  <v-expansion-panel
    v-show="topics.length > 0"
  >
    <v-expansion-panel-header>Trending topics</v-expansion-panel-header>
    <v-expansion-panel-content>
      <vue-word-cloud
        style="height: 240px;"
        :words="computedWords"
        :color="([, weight]) => weight > median ? '#303F9F' : '#7986CB'"
        font-family="Roboto"
        :font-size-ratio="3"
        :spacing="0.3"
        :weight="1"
        font-weight="500"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import VueWordCloud from 'vuewordcloud';

export default {
  name: 'ListTrendingTopics',

  components: {
    VueWordCloud,
  },

  props: {
    trendingTopicsType: {
      type: String,
      required: true,
    },
  },

  computed: {
    topics() { return this.$store.getters[this.trendingTopicsType]; },

    computedWords() {
      const results = [];
      this.topics.forEach((item) => {
        results.push([item.topic, item.count]);
      });

      return results;
    },

    median() {
      // This finds the median in an array of counts so we make sure
      // there are always two colors used.
      const arr = [];
      this.topics.forEach((item) => {
        arr.push(item.count);
      });
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
