<template>
  <div>
    <v-tooltip
      :disabled="!disableSlider || $vuetify.breakpoint.mdAndDown"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex align-center">
            <div
              class="d-inline-block"
              style="width: 85%;"
            >
              <v-slider
                v-model="minimalMatchPercentage"
                hide-details
                class="align-center custom-bg"
                color="#0c567a"
                :value="minimalMatchPercentage"
                min="1"
                :disabled="disableSlider"
                @mouseup="updateTimelineWithNewPercentage"
              />
            </div>
            <div
              v-if="!disableSlider"
              class="d-flex d-inline-block justify-end pr-1 text-body-2"
              style="width: 14%;"
              v-text="`${minimalMatchPercentage}%`"
            />
          </div>
        </div>
      </template>
      <span>Je zoekt momenteel binnen alle contexten, dus eigenlijk
        zonder context. Gebruik van de slider is daarom niet nodig.</span>
    </v-tooltip>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  name: 'FilterMinimalMatchPercentage',

  data() {
    return {
      percentage: null,
      disableSlider: false,
    };
  },

  computed: {
    minimalMatchPercentage: {
      get() {
        return this.$store.getters.searchParameterMinimalMatchPercentage;
      },
      set(percentage) {
        this.$store.dispatch('setMinimalMatchPercentage', percentage);
        this.$gtag.event('search-filter-change-minimal-match-percentage', {
          event_category: 'search-filter',
          evnt_value: percentage,
        });
      },
    },
  },

  methods: {
    // setMinimalMatchPercentage(percentage) {
    //   this.$gtag.event('search-filter-change-minimal-match-percentage', {
    //     event_category: 'search-filter',
    //     evnt_value: percentage,
    //   });

    //   this.$store.dispatch('setMinimalMatchPercentage', percentage);
    //   // Disabled till there is a solution to update the
    //   // recent search item when the percentage changes
    //   // this.$store.dispatch('getAllTimelineItems', true);
    //   this.$store.dispatch('getAllTimelineItems');
    // },

    updateTimelineWithNewPercentage() {
      // this.$store.dispatch('setMinimalMatchPercentage', percentage);
      this.$store.dispatch('nlGetAllTimelineItems');
    },

    toggleSlider(payload) {
      this.disableSlider = payload;
    },
  },

  mounted() {
    const that = this;
    EventBus.$on('setMatchPercentageSlider', (payLoad) => {
      that.toggleSlider(payLoad);
    });
  },
};

</script>
