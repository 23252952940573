/*
 * This file contains validators for component properties.
 * Only use this file for validators which need to be shared between components
 */

import Config from '@/config';

const PropValidators = {
  percentage(value) {
    let validValue = value;
    if (typeof validValue !== 'number') {
      validValue = parseInt(validValue, 10);
    }
    return validValue <= 100;
  },

  TimelineItemCategory(value) {
    // Grab all categories from Config and validate against
    return Object.keys(Config.timelineCategories).indexOf(value) !== -1;
  },

};

export default PropValidators;
